import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Start from '../../versions/caleb/start'

class RootIndexEN extends React.Component {
  render() {
    const projects = get(this, 'props.data.allContentfulProject.nodes')
    return (
      <Start
        projects={projects}
        lang="en"
      />

    )
  }
}

export default RootIndexEN

export const pageQuery = graphql`
query HomeQueryEn {
  allContentfulProject(
    sort: {fields: title, order: DESC}
    filter: {node_locale: {eq: "en-US"}}
  ) {
    nodes {
      title
      slug
      year
      type
      genre
      hasPage
      poster {
        image {
          gatsbyImageData(placeholder: BLURRED, width: 1000)
        }
      }
      pitch {
        childMarkdownRemark {
          html
        }
      }
    }
  }
}
`
